import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;

  constructor(public auth: AuthService) {}

  ngOnInit() {
    console.log(this.auth.afAuth.authState.subscribe());
  }
}
