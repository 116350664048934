import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Project } from "./model/project";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ProjectService {
  projectsCollection: AngularFirestoreCollection<Project>;
  projects: Observable<Project[]>;

  constructor(private af: AngularFirestore, public router: Router) {
    this.projectsCollection = this.af.collection("projects");
  }

  getAll(): Observable<Project[]> {
    return this.projectsCollection.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Project;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  create(data) {
    this.projectsCollection
      .add(data)
      .then(() => {
        this.router.navigate(["projects"]);
      })
      .catch(err => console.log("err", err));
  }

  delete(id: string) {
    this.projectsCollection.doc(id).delete();
  }
}
