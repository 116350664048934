import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamicweb',
  templateUrl: './dynamicweb.component.html',
  styleUrls: ['./dynamicweb.component.css']
})
export class DynamicwebComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
