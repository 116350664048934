import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ProjectService } from "../project.service";
import { Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/storage";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.css"]
})
export class CreateComponent implements OnInit {
  imageUrl: string = null;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  form = this.fb.group({
    title: ["", Validators.required],
    content: ["", Validators.required],
    website: ["", Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    public projectService: ProjectService,
    private afStorage: AngularFireStorage
  ) {}

  ngOnInit() {}

  uploadImage(event) {
    const file = event.target.files[0];
    if (file.type.split("/")[0] !== "image") {
      return alert("Only image files");
    } else {
      const filePath = `projects/${file.name}`;
      const fileRef = this.afStorage.ref(filePath);
      const task = this.afStorage.upload(filePath, file);
      this.uploadPercent = task.percentageChanges();
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(url => {
              this.imageUrl = url;
            });
          })
        )
        .subscribe();
    }
  }

  create() {
    let project = {
      uid: this.auth.uid,
      imageUrl: this.imageUrl,
      title: this.form.value.title,
      content: this.form.value.content,
      website: this.form.value.website,
      createdAt: Date.now()
    };
    this.projectService.create(project);
    this.form.reset();
  }
}
