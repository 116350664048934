import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  showLogin: boolean = true;
  showSignup: boolean = false;
  showReset: boolean = false;

  form = this.fb.group({
    email: ["", Validators.required],
    password: ["", Validators.required]
  });
  form2 = this.fb.group({
    email: ["", Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    public router: Router
  ) {}

  ngOnInit() {}

  noAccount() {
    this.showLogin = false;
    this.showSignup = true;
    this.showReset = false;
  }

  resetPsw() {
    this.showLogin = false;
    this.showReset = true;
    this.showSignup = false;
  }

  hasAccount() {
    this.showLogin = true;
    this.showReset = false;
    this.showSignup = false;
  }

  onSignUp() {
    this.auth.signup(this.form.value);
    this.form.reset();
    this.showSignup = false;
  }
  onLogin() {
    this.auth.login(this.form.value);
    this.form.reset();
    this.router.navigate(["/create"]);
  }
  onReset() {
    console.log("reset", this.form2.value);
    this.auth.resetPassword(this.form2.value.email);
    this.form2.reset();
    this.router.navigate(["/"]);
  }
}
