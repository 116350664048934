import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../project.service";
import { Observable } from "rxjs";
import { Project } from "../model/project";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"]
})
export class ProjectsComponent implements OnInit {
  projects: Observable<Project[]>;
  constructor(
    public projectService: ProjectService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.projects = this.projectService.getAll();
  }

  delete(project) {
    if (this.auth.uid != project.uid) return;
    this.projectService.delete(project.id);
  }
}
