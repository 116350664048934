import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HomeComponent } from "./home/home.component";
import { ProjectsComponent } from "./projects/projects.component";
import { AboutComponent } from "./about/about.component";
import { LoginComponent } from "./login/login.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreateComponent } from "./create/create.component";
import { AuthGuard } from "./auth.guard";
import { StaticwebComponent } from "./staticweb/staticweb.component";
import { UpdatewebComponent } from "./updateweb/updateweb.component";
import { DynamicwebComponent } from "./dynamicweb/dynamicweb.component";
import { ContractComponent } from "./contract/contract.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "staticweb", component: StaticwebComponent },
  { path: "updateweb", component: UpdatewebComponent },
  { path: "dynamicweb", component: DynamicwebComponent },
  { path: "contract", component: ContractComponent },
  { path: "projects", component: ProjectsComponent },
  { path: "about", component: AboutComponent },
  { path: "login7188", component: LoginComponent },
  { path: "create", component: CreateComponent, canActivate: [AuthGuard] },
  { path: "**", component: NotfoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectsComponent,
    AboutComponent,
    LoginComponent,
    NotfoundComponent,
    NavbarComponent,
    FooterComponent,
    CreateComponent,
    StaticwebComponent,
    UpdatewebComponent,
    DynamicwebComponent,
    ContractComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    NgbModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
