import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  authState: any = null;
  constructor(public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe(auth => {
      this.authState = auth;
    });
  }

  get isLoggedIn(): boolean {
    return this.authState !== null;
  }

  get uid(): string {
    return this.authState.uid;
  }

  get currentUser() {
    return this.authState;
  }

  redirectUrl: string;

  signup(data) {
    console.log("sign up");
    this.afAuth.auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .catch(err => {
        console.log("err", err);
      });
  }

  login(data) {
    this.afAuth.auth
      .signInWithEmailAndPassword(data.email, data.password)
      .catch(err => {
        console.log("err", err);
      });
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  resetPassword(email: string) {
    this.afAuth.auth
      .sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch(error => console.log(error));
  }
}
