import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staticweb',
  templateUrl: './staticweb.component.html',
  styleUrls: ['./staticweb.component.css']
})
export class StaticwebComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
